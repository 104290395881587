@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cls-1{
  fill:#011b39;
}

.cls-2{fill:#1275b4;}

.cls-3{fill:#edd337;}

.cls-4{fill:#fff;}

.cls-5{fill:#eb1c24;}

#logo-wrapper {
  padding-top: 1rem;
  max-width: 300px;
  margin: 0 auto;

  .star.left {
    animation: blink 5s infinite forwards;
    animation-delay: 0.5s;
  }

  .star.center {
    animation: blink 5s infinite forwards;
    animation-delay: 1s;
  }

  .star.near-right {
    animation: blink 5s infinite forwards;
    animation-delay: 1.5s;
  }

  .star.far-right {
    animation: blink 5s infinite forwards;
    animation-delay: 2s;
  }
}