@import './styles/_palette.scss';
@import './styles/_logo-svg.scss';
@import './styles/_instruments-widget.scss';
@import './styles/_star-generator.scss';
@import './styles/_accordion.scss';
@import './styles/_typog.scss';
@import './styles/_breakpoints.scss';
@import './styles/_calendar.scss';

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12pt;
  overflow: hidden;
  color: $ge-yellow;
  background-color: #111a2b;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%234d78bc' stroke-width='10' stroke-opacity='0.21'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
  
  @media screen and (max-width: $small-screen) {
  	overflow: auto;
  }
}


.App {
  text-align: center;

  * {
    transition: all .2s;
  }
}

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase;
}

a,
a:link {
  text-decoration: none;
  color: $ge-blue2;

  &:hover {
    color: $ge-yellow;
  }
}

p {
  line-height: 1.5;
}

$side-panel-width: 20vw;
$trans-bg: rgba(black, .7);

.grand-ephemeris-home {
  height: 100vh;
  width: 100vw;
  display: flex;

  @media screen and (max-width: $small-screen) {
  	display: block;

  	iframe {
  		width: 100%;
  		overflow: hidden;
  	}

  	img {
  		max-width: 100%;
  	}
  }

  .position-display {
    color: white;

    @media screen and (max-width: $small-screen) {
    	display: none;
	}

    span {
      color: $ge-yellow;
    }

    .dash-val {
      display: inline-block;
      width: 46px;
    }
  }

  .attribution {
    width: $side-panel-width;
    padding-right: 2em;
    background-color: $trans-bg;
    z-index: 10;
    border-right: 2px solid $ge-yellow;

    @media screen and (max-width: $small-screen) {
    	width: 100%;
    	padding-bottom: 2em;
    	border-bottom: 2px solid $ge-yellow;
	}

    .ge-desc-quote {
    	color: $ge-green;
    	padding: 0 2em;
    	font-family: $app-font;
    	line-height: 1;
    }
  }

  .main-panel {
    padding-left: 2em;
    width: 100 - $side-panel-width;
    text-align: left;
    height: 100vh;
    overflow-y: auto;
    z-index: 10;

    @media screen and (max-width: $small-screen) {
    	width: 100%;
    	padding: 0;
    	height: auto;

    	h2 {
    		text-align: center;
    	}

    	.discog-menu {
    		display: none;
    	}
	 }
  }

  .content-panel {
    max-width: 800px;
    background-color: $trans-bg;
    padding: 1em;
    margin: 2em 0;
    border-top: 2px solid $ge-yellow;
    position: relative;

    @media screen and (max-width: $small-screen) {
    	max-width: 100%;
	  }

    h3 {
      margin: 0 0 .5em 0;
      padding: 0;
      font-size: 250%;
      color: $ge-green;
      font-family: $app-font;
      line-height: 1;
    }

    h4 {
      padding-bottom: .5em;
      margin-bottom: 1em;
      border-bottom: 1px solid rgba(white, .3);
      color: $ge-blue2;
    }

    .album-info {
      padding-bottom: 1em;
      font-family: $header-font;
      text-transform: uppercase;
      color: $ge-blue2;
      font-weight: bold;
      font-size: 1.25rem;
    }

    img {
      border: 5px solid white;
      max-width: 100%;
    }
  }
}

.ge-description {
	color: white;
}

.instrument-panel {
  text-align: left;
  padding: 1em 1.5em;
  color: white;
  max-width: 250px;
  margin: 0 auto;

  @media screen and (max-width: $small-screen) {
  	display: none;
  }

  .panel-field {
    padding: .5rem 0;
    margin: 0;
    border-bottom: 1px solid rgba(white, .3);
  }

  strong {
    color: $ge-yellow;
  }
}

.left-panel {
  .symbol {
    margin-left: .5rem;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: $very-small-screen) {
  	display: block;
  }

  p {
    width: 50%;
    color: white;
    
    @media screen and (max-width: $very-small-screen) {
	  	width: 100%;
	  }
  }
}

@mixin btn {
  padding: 1em 1.5em;
  margin: 0 .5rem .5rem 0;
  text-align: center;
  color: $ge-green;
  text-transform: uppercase;
  background-color: rgba(black, .5);
  text-decoration: none;
  border: 2px solid transparent;

  &:hover {
    color: $ge-yellow;
    border-color: $ge-yellow;
    background-color: rgba($ge-blue2, .3);
  }

  @media screen and (max-width: $very-small-screen) {
  	display: block;
  }
}

.stream a {
  @include btn;
}

.lyrics {
  padding-bottom: 1em;
}

.ge-contact {
  text-transform: uppercase;
  font-size: 130%;
  background-color: transparent;
  padding: 1em;
  text-align: center;
  display: block;
  border: 1px solid rgba(white, .4);
  padding-bottom: 1em;
  border-radius: 5px;
  color: $ge-green !important;
  margin-top: 1rem;

  &:hover {
    color: rgba(black, .8) !important;
    background: $ge-green;
  }
}

.new-release-badge {
	display: inline-block;
	padding: .5rem 1.5rem;
	background-color: $ge-yellow;
	color: $ge-blue;
	text-transform: uppercase;
	position: absolute;
	top: 0;
	right: 1rem;
	font-weight: bold;
	border-bottom: 2px solid $ge-blue2;

	@media screen and (max-width: $very-small-screen) {
		display: none;
	}
}

.ge-script {
	font-family: $app-font;
	color: $ge-green;
	font-size: 120%;
}

.ge-contact {
  margin-bottom: 1em;
}

.gb-embed {
  border: 0; width: 100%; height: 42px;
}

.bandcamp-embed {
  border: 0; width: 100%; height: 120px;
}

.quick-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  a {
    @include btn;
  }
}