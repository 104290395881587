@import url(https://fonts.googleapis.com/css?family=Permanent+Marker&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@-webkit-keyframes blink {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes blink {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.cls-1 {
  fill: #011b39; }

.cls-2 {
  fill: #1275b4; }

.cls-3 {
  fill: #edd337; }

.cls-4 {
  fill: #fff; }

.cls-5 {
  fill: #eb1c24; }

#logo-wrapper {
  padding-top: 1rem;
  max-width: 300px;
  margin: 0 auto; }
  #logo-wrapper .star.left {
    -webkit-animation: blink 5s infinite forwards;
            animation: blink 5s infinite forwards;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s; }
  #logo-wrapper .star.center {
    -webkit-animation: blink 5s infinite forwards;
            animation: blink 5s infinite forwards;
    -webkit-animation-delay: 1s;
            animation-delay: 1s; }
  #logo-wrapper .star.near-right {
    -webkit-animation: blink 5s infinite forwards;
            animation: blink 5s infinite forwards;
    -webkit-animation-delay: 1.5s;
            animation-delay: 1.5s; }
  #logo-wrapper .star.far-right {
    -webkit-animation: blink 5s infinite forwards;
            animation: blink 5s infinite forwards;
    -webkit-animation-delay: 2s;
            animation-delay: 2s; }

@-webkit-keyframes switchFly1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  30% {
    -webkit-transform: translateY(140px);
            transform: translateY(140px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes switchFly1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  30% {
    -webkit-transform: translateY(140px);
            transform: translateY(140px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes switchFly2 {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(160px);
            transform: translateY(160px); }
  90% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes switchFly2 {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(160px);
            transform: translateY(160px); }
  90% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes switchFly3 {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px); }
  70% {
    -webkit-transform: translateY(60px);
            transform: translateY(60px); }
  90% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes switchFly3 {
  0% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); }
  30% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px); }
  70% {
    -webkit-transform: translateY(60px);
            transform: translateY(60px); }
  90% {
    -webkit-transform: translateY(175px);
            transform: translateY(175px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes blipBop {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  30% {
    -webkit-transform: translateY(127px);
            transform: translateY(127px); }
  70% {
    -webkit-transform: translateX(150px);
            transform: translateX(150px); }
  90% {
    -webkit-transform: translateY(157px);
            transform: translateY(157px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@keyframes blipBop {
  0% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); }
  30% {
    -webkit-transform: translateY(127px);
            transform: translateY(127px); }
  70% {
    -webkit-transform: translateX(150px);
            transform: translateX(150px); }
  90% {
    -webkit-transform: translateY(157px);
            transform: translateY(157px); }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px); } }

@-webkit-keyframes outThenIn {
  0% {
    opacity: 0; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes outThenIn {
  0% {
    opacity: 0; }
  60% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.ge-instrument-widgets {
  padding: 1em 1.5em 0 1.5em;
  max-width: 200px; }
  .ge-instrument-widgets .widget rect,
  .ge-instrument-widgets .widget path,
  .ge-instrument-widgets .widget circle {
    fill: #edd337; }
  .ge-instrument-widgets #vert-meter path {
    fill: #edd337; }
  .ge-instrument-widgets .text-panel rect {
    fill: #edd337 !important; }
  .ge-instrument-widgets .text-panel-text {
    fill: white !important;
    -webkit-animation: outThenIn 2s infinite;
            animation: outThenIn 2s infinite; }
  .ge-instrument-widgets .switch {
    fill: #4d78bc !important; }
    .ge-instrument-widgets .switch.left-switch {
      -webkit-animation: switchFly1 3s infinite;
              animation: switchFly1 3s infinite;
      fill: #eb1c24 !important; }
    .ge-instrument-widgets .switch.center-switch {
      -webkit-animation: switchFly2 10s infinite;
              animation: switchFly2 10s infinite; }
    .ge-instrument-widgets .switch.right-switch {
      -webkit-animation: switchFly3 20s infinite;
              animation: switchFly3 20s infinite; }
  .ge-instrument-widgets .radar-blip {
    fill: #eb1c24 !important;
    -webkit-animation: blipBop 10s infinite;
            animation: blipBop 10s infinite; }
  .ge-instrument-widgets #bottom-text rect {
    fill: #eb1c24 !important; }

@-webkit-keyframes warpSpeed {
  0% {
    opacity: 0; }
  50% {
    opacity: .7; }
  99% {
    opacity: 0;
    width: 2px;
    height: 1000%; } }

@keyframes warpSpeed {
  0% {
    opacity: 0; }
  50% {
    opacity: .7; }
  99% {
    opacity: 0;
    width: 2px;
    height: 1000%; } }

.star-generator {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }
  @media screen and (max-width: 1000px) {
    .star-generator {
      display: none; } }
  .star-generator .star {
    opacity: 0;
    display: inline-block;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    -webkit-animation: warpSpeed 5s ease-in-out;
            animation: warpSpeed 5s ease-in-out;
    -webkit-animation-delay: 36s;
            animation-delay: 36s;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }

.ge-accordion:hover {
  cursor: pointer; }
  .ge-accordion:hover .ge-accordion-title {
    color: white; }

.ge-accordion h2 {
  border-bottom: 1px solid transparent; }

.ge-accordion.open h2 {
  border-color: rgba(255, 255, 255, 0.4);
  margin-bottom: .5em; }

.ge-accordion .indicator {
  display: inline-block;
  padding: 0 .5em;
  font-size: 120%; }
  .ge-accordion .indicator.tilted {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

.ge-accordion .ge-accordion-content {
  padding: .25rem 0 .25rem 2em;
  margin: 0; }

.ge-accordion .ge-accordion-title {
  text-align: left !important;
  color: #edd337;
  font-family: "Permanent Marker", cursive;
  margin-bottom: 0; }
  @media screen and (max-width: 1000px) {
    .ge-accordion .ge-accordion-title {
      font-size: 100%; } }

.calendar .calendar-entry {
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 2%; }
  @media only screen and (max-width: 500px) {
    .calendar .calendar-entry {
      display: block; } }
  .calendar .calendar-entry section {
    padding: .5em 3em; }
  @media only screen and (max-width: 500px) {
    .calendar .calendar-entry {
      padding: .5em 1em; } }

.calendar .date {
  text-align: center;
  font-family: "Permanent Marker", cursive;
  color: #edd337;
  border-right: 1px solid rgba(255, 255, 255, 0.2); }
  @media only screen and (max-width: 500px) {
    .calendar .date {
      border-right: none; } }
  .calendar .date .day {
    font-size: 240%; }
  .calendar .date .month {
    margin-top: -10px;
    font-size: 120%; }

.calendar .venue {
  text-align: left;
  width: 60%;
  border-right: 1px solid rgba(255, 255, 255, 0.2); }
  @media only screen and (max-width: 500px) {
    .calendar .venue {
      border-right: none;
      text-align: center; } }
  .calendar .venue .venue-name a {
    font-size: 200%; }
  .calendar .venue .address {
    font-size: 85%; }
  .calendar .venue .map-link {
    display: block;
    font-size: 85%;
    margin-top: .25em; }

.calendar .time {
  text-align: center;
  width: 30%;
  padding-top: 3% !important;
  font-family: "Permanent Marker", cursive;
  font-size: 160%;
  color: #edd337; }

@media only screen and (max-width: 500px) {
  .calendar {
    text-align: center; } }

.calendar p {
  margin: 0; }

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 12pt;
  overflow: hidden;
  color: #edd337;
  background-color: #111a2b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='30' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%234d78bc' stroke-width='10' stroke-opacity='0.21'%3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E"); }
  @media screen and (max-width: 1000px) {
    html,
    body {
      overflow: auto; } }

.App {
  text-align: center; }
  .App * {
    transition: all .2s; }

h1,
h2,
h3,
h4,
h5 {
  text-transform: uppercase; }

a,
a:link {
  text-decoration: none;
  color: #4d78bc; }
  a:hover,
  a:link:hover {
    color: #edd337; }

p {
  line-height: 1.5; }

.grand-ephemeris-home {
  height: 100vh;
  width: 100vw;
  display: flex; }
  @media screen and (max-width: 1000px) {
    .grand-ephemeris-home {
      display: block; }
      .grand-ephemeris-home iframe {
        width: 100%;
        overflow: hidden; }
      .grand-ephemeris-home img {
        max-width: 100%; } }
  .grand-ephemeris-home .position-display {
    color: white; }
    @media screen and (max-width: 1000px) {
      .grand-ephemeris-home .position-display {
        display: none; } }
    .grand-ephemeris-home .position-display span {
      color: #edd337; }
    .grand-ephemeris-home .position-display .dash-val {
      display: inline-block;
      width: 46px; }
  .grand-ephemeris-home .attribution {
    width: 20vw;
    padding-right: 2em;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    border-right: 2px solid #edd337; }
    @media screen and (max-width: 1000px) {
      .grand-ephemeris-home .attribution {
        width: 100%;
        padding-bottom: 2em;
        border-bottom: 2px solid #edd337; } }
    .grand-ephemeris-home .attribution .ge-desc-quote {
      color: #edd337;
      padding: 0 2em;
      font-family: "Permanent Marker", cursive;
      line-height: 1; }
  .grand-ephemeris-home .main-panel {
    padding-left: 2em;
    width: 80vw;
    text-align: left;
    height: 100vh;
    overflow-y: auto;
    z-index: 10; }
    @media screen and (max-width: 1000px) {
      .grand-ephemeris-home .main-panel {
        width: 100%;
        padding: 0;
        height: auto; }
        .grand-ephemeris-home .main-panel h2 {
          text-align: center; }
        .grand-ephemeris-home .main-panel .discog-menu {
          display: none; } }
  .grand-ephemeris-home .content-panel {
    max-width: 800px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 1em;
    margin: 2em 0;
    border-top: 2px solid #edd337;
    position: relative; }
    @media screen and (max-width: 1000px) {
      .grand-ephemeris-home .content-panel {
        max-width: 100%; } }
    .grand-ephemeris-home .content-panel h3 {
      margin: 0 0 .5em 0;
      padding: 0;
      font-size: 250%;
      color: #edd337;
      font-family: "Permanent Marker", cursive;
      line-height: 1; }
    .grand-ephemeris-home .content-panel h4 {
      padding-bottom: .5em;
      margin-bottom: 1em;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      color: #4d78bc; }
    .grand-ephemeris-home .content-panel .album-info {
      padding-bottom: 1em;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      color: #4d78bc;
      font-weight: bold;
      font-size: 1.25rem; }
    .grand-ephemeris-home .content-panel img {
      border: 5px solid white;
      max-width: 100%; }

.ge-description {
  color: white; }

.instrument-panel {
  text-align: left;
  padding: 1em 1.5em;
  color: white;
  max-width: 250px;
  margin: 0 auto; }
  @media screen and (max-width: 1000px) {
    .instrument-panel {
      display: none; } }
  .instrument-panel .panel-field {
    padding: .5rem 0;
    margin: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  .instrument-panel strong {
    color: #edd337; }

.left-panel .symbol {
  margin-left: .5rem; }

.gallery {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (max-width: 700px) {
    .gallery {
      display: block; } }
  .gallery p {
    width: 50%;
    color: white; }
    @media screen and (max-width: 700px) {
      .gallery p {
        width: 100%; } }

.stream a {
  padding: 1em 1.5em;
  margin: 0 .5rem .5rem 0;
  text-align: center;
  color: #edd337;
  text-transform: uppercase;
  background-color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  border: 2px solid transparent; }
  .stream a:hover {
    color: #edd337;
    border-color: #edd337;
    background-color: rgba(77, 120, 188, 0.3); }
  @media screen and (max-width: 700px) {
    .stream a {
      display: block; } }

.lyrics {
  padding-bottom: 1em; }

.ge-contact {
  text-transform: uppercase;
  font-size: 130%;
  background-color: transparent;
  padding: 1em;
  text-align: center;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 1em;
  border-radius: 5px;
  color: #edd337 !important;
  margin-top: 1rem; }
  .ge-contact:hover {
    color: rgba(0, 0, 0, 0.8) !important;
    background: #edd337; }

.new-release-badge {
  display: inline-block;
  padding: .5rem 1.5rem;
  background-color: #edd337;
  color: #111a2b;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  right: 1rem;
  font-weight: bold;
  border-bottom: 2px solid #4d78bc; }
  @media screen and (max-width: 700px) {
    .new-release-badge {
      display: none; } }

.ge-script {
  font-family: "Permanent Marker", cursive;
  color: #edd337;
  font-size: 120%; }

.ge-contact {
  margin-bottom: 1em; }

.gb-embed {
  border: 0;
  width: 100%;
  height: 42px; }

.bandcamp-embed {
  border: 0;
  width: 100%;
  height: 120px; }

.quick-links-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  .quick-links-wrapper a {
    padding: 1em 1.5em;
    margin: 0 .5rem .5rem 0;
    text-align: center;
    color: #edd337;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.5);
    text-decoration: none;
    border: 2px solid transparent; }
    .quick-links-wrapper a:hover {
      color: #edd337;
      border-color: #edd337;
      background-color: rgba(77, 120, 188, 0.3); }
    @media screen and (max-width: 700px) {
      .quick-links-wrapper a {
        display: block; } }

