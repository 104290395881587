@import './_typog.scss';
@import './_breakpoints.scss';

.ge-accordion {
	
	&:hover {
		cursor: pointer;

		.ge-accordion-title {
			color: white;
		}
	}

	h2 {
		border-bottom: 1px solid transparent;
	}

	&.open {
		h2 {
			border-color: rgba(white, .4);
			margin-bottom: .5em;
		}
	}

	.indicator {
		display: inline-block;
		padding: 0 .5em;
		font-size: 120%;

		&.tilted {
			transform: rotate(90deg);
		}
	}

	.ge-accordion-content {
		padding: .25rem 0 .25rem 2em;
		margin: 0;
	}

	.ge-accordion-title {
		text-align: left !important;
		color: $ge-green;
    	font-family: $app-font;
    	margin-bottom: 0;

		 @media screen and (max-width: $small-screen) {
		  	font-size: 100%;
		 }
	}
}