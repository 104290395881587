@import './_breakpoints.scss';

@keyframes warpSpeed {
  0% {
  	opacity: 0;
  }	
  50% {
  	opacity: .7;
  }
  99% {
    opacity: 0;
    width: 2px;
    height: 1000%;
  }
}

.star-generator {
	position: absolute; 
	top:0; 
	bottom:0; 
	width: 100%;
	height: 100%;
	z-index: 1;

	@media screen and (max-width: $small-screen) {
		display: none;
	}

	.star {
    opacity: 0;
		display: inline-block;
		border-radius: 50%;
		background-color: white;
		position: absolute;
		animation: warpSpeed 5s ease-in-out;
		animation-delay: 36s;
		backface-visibility: hidden;
	}
}