@keyframes switchFly1 {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(140px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes switchFly2 {
  0% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(160px);
  }
  90% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes switchFly3 {
  0% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(175px);
  }
  70% {
    transform: translateY(60px);
  }
  90% {
    transform: translateY(175px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blipBop {
  0% {
    transform: translateX(-5px);
  }
  30% {
    transform: translateY(127px);
  }
  70% {
    transform: translateX(150px);
  }
  90% {
    transform: translateY(157px);
  }
  100% {
    transform: translateY(0px);
  }
}


@keyframes outThenIn {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ge-instrument-widgets {
	padding: 1em 1.5em 0 1.5em;
	max-width: 200px;

	.widget {
		rect,
		path,
		circle {
			fill: $ge-yellow;
		}
	}

	#vert-meter path {
		fill: $ge-green;
	}

	.text-panel rect {
		fill: $ge-green !important;
	}

	.text-panel-text {
		fill: white !important;
		animation: outThenIn 2s infinite;
	}

	.switch {
		fill: $ge-blue2 !important;

		&.left-switch {
			animation: switchFly1 3s infinite;
      fill: $ge-red !important;
		}

		&.center-switch {
			animation: switchFly2 10s infinite;
		}

		&.right-switch {
			animation: switchFly3 20s infinite;
		}
	}

	.radar-blip {
		fill: $ge-red !important;
		animation: blipBop 10s infinite;
	}

  #bottom-text {
    rect {
      fill: $ge-red !important;
    }
  }
}